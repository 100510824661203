















































import {Component, Vue} from "vue-property-decorator";
import PanelScreen from "@/models/PanelScreen";
import User from "@/models/User";
import PanelScreenService from "@/services/PanelScreenService";
import UserService from "@/services/UserService";
import {AuthorityName} from "@/models/Authority";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";

@Component
export default class PanelScreenView extends Vue {
	lang: any = getModule(LangModule).lang
	panelScreens: PanelScreen[] = []
	myPanelScreens: PanelScreen[] = []
	users: User[] = []
	userId: number = 0
	panelScreenId: number = 0
	loading: boolean = false
	totalItems: number = 1500
	headers = [
		{text: this.lang.pathName, value: "title", width: "200px"},
		{text: this.lang.path, value: "route", width: "200px"},
	]

	relate() {
		if (this.userId && this.panelScreenId) {
			PanelScreenService.patchPanelScreenRelateUser(this, this.panelScreenId, this.userId)
		}
	}

	created() {
		PanelScreenService.getPanelScreen(this, this.panelScreens)
		UserService.getUsers(this, this.users, 0, this.totalItems, AuthorityName.ADMIN, "")
		this.refresh()
	}

	refresh() {
		if (this.userId) {
			PanelScreenService.getPanelScreenByUserId(this, this.userId, this.myPanelScreens)
		}
	}

}
